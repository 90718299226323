@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.or {
		@apply relative text-center z-10;
	}
	
	.or::after {
		@apply block absolute top-[50%] z-20 w-full h-[2px];
		content: '';
		background-color: #ccc;
	}
	
	.or span {
		@apply relative inline-block z-30 px-3 py-4 bg-white;
	}

	.search-header {
		display: block;
	}

	.search-header__history {
		@apply mb-3;
	}

	.search-header__create {
		@apply mb-3;
	}

	.search-header__find {
		@apply mb-3;
	}

	@media only screen and (min-width: 460px) {
		.search-header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.search-header__find {
			width: 100%;
		}
	}

	@media only screen and (min-width: 700px) {
		.search-header {
			display: grid;
			grid-template-columns: auto 1fr auto;
			align-items: center;
			column-gap: 15px;
		}

		.search-header__find {
			grid-column: 2;
			grid-row: 1;
			display: flex;
			justify-content: center;
		}

		.search-header__create {
			grid-column: 3;
		}
	}
}

* {
	box-sizing: border-box;
}

html, body {
	min-height: 100vh;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#__next {
	min-height: 100vh;
}

a {
	color: inherit;
	text-decoration: none;
}

.icon-burger:hover .fa-bars {
	color: #676767;
}

.svg-inline--fa.fa-sort-up {
	vertical-align: -0.35em;
}

.svg-inline--fa.fa-sort-down {
	vertical-align: 0.125em;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}
